import ReceiptInfo from "./ReceiptInfo";
import ReceiptPayout from "./ReceiptPayout";

function Receipt(props) {

  return (
    <div className="box-receipt">
      <ReceiptInfo data={props.data}/>
      <ReceiptPayout data={props.data}/>
    </div>
  );
}

export default Receipt;
