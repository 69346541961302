import logo from "../assets/logo.jpeg";

function Header() {
  return (
    <div className="header">
      <p>
      <a href="https://www.estetiderma.co.id">
        <img src={logo} className="logo" style={{width: "95%", height: "100%"}}/>
        </a>
      </p>
      {/* <h2>Estetiderma</h2> */}
    </div>
  );
}

export default Header;
