// import { useState } from "react";
import axios from "axios";

function Experience(props) {

  // const [isSmileActive, setIsSmileActive] = useState(false)
  
  // const [isSadActive, setIsSadActive] = useState(false)

  function feedbackHandler (trxid, feedval) {
    // setIsSmileActive(true) 
      // Lakukan permintaan ke server dengan ID yang ada dalam URL
      axios
        .post(`https://edc-api.loginusa.id/edcservice/receipt/sendFeedback`, { 
        // .post(`http://192.168.18.51:3015/receipt/sendFeedback`, {
          "data": {
              "id": trxid,
              "feedback": feedval
          }
        })
        .then((response) => {
          console.log('response.data', JSON.stringify(response.data))
          if (feedval === 'Y') {
            alert('Terima kasih atas kunjungan dan feedback Anda.')
          } else {
            alert('Mohon maaf atas ketidaknyamanan anda !')
          }
          window.location.reload();
        })
        .catch((error) => { 
          alert("Gagal Memberikan feedback, harap coba sekali lagi..."); 
        }); 
  } 

  function renderButton() {
    if ((props.data&&props.data.feedback === 'Y')) {
      return (
        <div className="box-experience">
          <div className="experience__emoji-container">
            {/* <h6>Terimakasih, anda telah merasa puas terhadap transaksi ini.</h6> */}
          </div>
        </div>
      )
    } else if ((props.data&&props.data.feedback === 'N')) {
      return (
        <div className="box-experience">
          <div className="experience__emoji-container">
            {/* Tidak Suka ! */}
          </div>
        </div>
      )
    }
    return (
      <div className="box-experience">
        <h4>How was your experience?</h4>
        <div className="experience__emoji-container">
          <i class="bi bi-emoji-smile" style={{color: 'green'}} onClick={() => feedbackHandler(props.data.id,'Y')}></i>
          <div className="vertical-line"></div>
          <i class="bi bi-emoji-frown" style={{color: 'red'}} onClick={() =>  feedbackHandler(props.data.id,'N')}></i>
        </div>
      </div> 
    )
  }

  return (
    // <div className="box-experience">
    //   <h4>How was your experience?</h4>
      // {
        renderButton()
      // }
    // </div>
  );
}

export default Experience;
