

function Price (props) {

  function numberWithDot(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <div className="box-price">
      <h2>Rp. {numberWithDot(props.data.total)}</h2>
    </div>
  )
}

export default Price