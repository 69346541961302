import { useEffect, useState } from "react";
import Experience from "../Experience";
import Header from "../Header";
import Price from "../Price";
import Maps from "../Receipt/Maps";
import Receipt from "../Receipt/Receipt";
// import ThisIsACopy from "../ThisIsACopy";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// import { pdfjs, Document, Page } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();


function MainPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Lakukan permintaan ke server dengan ID yang ada dalam URL
    axios
      .get(`https://edc-api.loginusa.id/edcservice/receipt/findbyid/${id}`)
      //  .get(`http://localhost:3015/receipt/findbyid/${id}`)
      // .get(`http://192.168.18.51:3015/receipt/findbyid/${id}`)
      .then((response) => {
        // Jika berhasil, atur data
       console.log('response.data', JSON.stringify(response.data))
        setData(response.data);
      })
      .catch((error) => {
        // Jika terjadi kesalahan, atur pesan kesalahan dan/atau redirect ke halaman lain
        setError("Data tidak ditemukan");
        console.log(error);
        // navigate("/"); // Ganti dengan URL halaman lain jika diperlukan
      });
  }, [id, navigate]);

  //console.log(data);

  return (
    <div className="body-container">
      {error ? (
        <h3>Tidak ada resi dengan id: {id}</h3>
      ) : (
        data && (
          <div> 
              <Header />
              <div className="content-container">
                {/* <ThisIsACopy /> */}
                <Experience data={data}/>
                <Price data={data} />
                <Receipt data={data} />
                <Maps data={data} />
              </div> 
          </div>
        )
      )}
    </div>
  );
}

export default MainPage;
