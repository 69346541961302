import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import grevus from "../../assets/grevus.png"

function Maps(props) {
  const customIcon = new Icon({
    iconUrl: require("../../assets/pin.png"),
    iconSize: [38, 38],
  });

  // function clickHandler() {
  //   window.location.href = props.data.google_review_url
  // }

  

  return (
    <div style={{ flex: 1}}>
      <div className="box-map">
        {/* <a href={props.data.google_review_url}> */}
<div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: 20,
            paddingLeft: 2, 
            paddingRight:2
          }}>
            <h6 style={{  textAlign: 'center', color: '#3A3B3C'}}>Beri kami ulasan positif di Google Review, dan dapatkan <span style={{color: 'green', fontWeight: 'bold',}}>voucher diskon Rp. 25.000!</span></h6>
          </div>
          {/* </a> */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <a href={props.data.google_review_url} target="_blank" rel="noreferrer">
              <img src={grevus} style={{ width: 250, marginTop: 1, marginBottom: 25, cursor:"pointer" }} className="App-logo" alt="review-us" />
            </a>
          </div>


          <div className="map-container">
            <MapContainer
              center={{ lat: props.data.latitude, lng: props.data.longitude }}
              zoom={19}
              className="map-container"
              // zoomControl="false"
              // dragging="disabled"
              // dragging="false"
              dragging={false}
              touchZoom={false}
              keyboard={false}
              boxZoom={false}
              scrollWheelZoom={false}
              zoomControl={false}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker
                position={{ lat: props.data.latitude, lng: props.data.longitude }}
                icon={customIcon}
              ></Marker>
            </MapContainer>
          </div>
       
      </div> 
      
    </div>
  );
}

export default Maps;
