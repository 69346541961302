import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./components/Pages/HomePage";
import MainPage from "./components/Pages/MainPage";
// import axios from "axios";
import PairingPage from "./components/Pages/PairingPage";

const router = createBrowserRouter([
  { path: "/", element: <HomePage /> },
  { path: "/resi/:id", element: <MainPage /> },
  { path: "/pairing/:param", element: <PairingPage />},
  {},
]);

function App() {
  return (
  <RouterProvider router={router} />
  );
}

export default App;
