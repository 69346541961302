function ReceiptPayout(props) {

  function numberWithDot(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  function renderPayout(menus) {
    let comp = [];
    let i = 0;
    for (const data of menus) { 
      comp.push(
        <div key={i} className="receipt-payout__item">
          <p className="receipt-payout__item-name">{data.menu} &times; {data.qty ? data.qty : 1}</p>
          <p className="receipt-payout__item-price">Rp. {numberWithDot(data.price)}</p>
        </div>
      )
      i++;
    }

    return comp;
  }
  
  return (
    <div className="receipt-payout">
      <div className="receipt-payout_header">
        <hr></hr>
        <p>Items</p>
        <hr></hr>
      </div>
      <div className="receipt-payout_content">
        {
          renderPayout(props.data.menu)
        }
        
        {/* <div className="receipt-payout__item">
          <p className="receipt-payout__item-name">{props.data.menu[0].menu} &times; 1</p>
          <p className="receipt-payout__item-price">Rp. {numberWithDot(props.data.menu[0].price)}</p>
        </div>
        <div className="receipt-payout__item">
          <p className="receipt-payout__item-name">{props.data.menu[1].menu} &times; 1</p>
          <p className="receipt-payout__item-price">Rp. {numberWithDot(props.data.menu[1].price)}</p>
        </div>
        <div className="receipt-payout__item">
          <p className="receipt-payout__item-name">{props.data.menu[2].menu} &times; 1</p>
          <p className="receipt-payout__item-price">Rp. {numberWithDot(props.data.menu[2].price)}</p>
        </div> */}

      </div>
      <div>
        <div className="receipt-payout_summation">
        <hr></hr>
          <div className="receipt-payout__subtotal">
            <p>PPN</p>
            <p>Rp. {numberWithDot(props.data.totalTax?props.data.totalTax:"0")}</p>
          </div>
          <div className="receipt-payout__subtotal">
            <p>Subtotal</p>
            <p>Rp. {numberWithDot(props.data.subtotal)}</p>
          </div>

          <div className="receipt-payout__subtotal">
            <p>Potongan Voucher</p>
            <p>Rp. {numberWithDot(props.data.potVoucher?props.data.potVoucher:"0")}</p>
          </div>

          <hr></hr>
          <div className="receipt-payout__total">
            <p>Total</p>
            <p>Rp. {numberWithDot(props.data.total?props.data.total:"0")}</p>
          </div>
          <div className="receipt-payout__method">
            <p>Voucher</p>
            <p>{(props.data && props.data.voucher) ? props.data.voucher : ""}</p>
          </div>

          <div className="receipt-payout__method">
            <p>{props.data.paymentmethod?props.data.paymentmethod: "payment"}</p>
            <p>Rp. {numberWithDot(props.data.totalpayment?props.data.totalpayment:props.data.total)}</p>
          </div>


          {
            (props.data.paymentmethod2) ?
            <div className="receipt-payout__method">
              <p>{props.data.paymentmethod2?props.data.paymentmethod2: ""}</p>
              <p>Rp. {numberWithDot(props.data.totalpayment2?props.data.totalpayment2:0)}</p>
            </div>
            : null
          }

          {
            (props.data.paymentmethod3) ?
          <div className="receipt-payout__method">
            <p>{props.data.paymentmethod3?props.data.paymentmethod3: ""}</p>
            <p>Rp. {numberWithDot(props.data.totalpayment3?props.data.totalpayment3:0)}</p>
          </div>
          : null
          }
          

        </div>
      </div>
    </div>
  );
}

export default ReceiptPayout;
