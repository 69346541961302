import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

function HomePage() {
  const [inputedId, setInputedID] = useState("null");

  const navigate = useNavigate();

  function changeId () {
    setInputedID("")
  }
  
  function changeId2 () {
    setInputedID("6532226240c1f731fea7375c")
  }
  
  function changeId3 () {
    setInputedID("65321dd140c1f731fea73758")
  }

  const blabla = () => {
    axios({
      method: "get",
      url: `https://edc-api.loginusa.id/edcservice/receipt/findbyid/${inputedId}`,
    })
      .then(function (response) {
        // handle success
        navigate(`/resi/${response.data.id}`);
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  return (
    <div>
      <h1>Homepage Page</h1>
      <h2>
        {" "}
        Go to <Link to="/resi"> resi</Link>{" "}
      </h2>
        <button onClick={changeId}>Set ID 1</button>
        <button onClick={changeId2}>Set ID 2</button>
        <button onClick={changeId3}>Set ID 3</button>
        <button onClick={blabla}>Go to Page</button>
      <h2>ID: {inputedId}</h2>
    </div>
  );
}

export default HomePage;
