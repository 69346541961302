import moment from 'moment';
function ReceiptInfo(props) {

  function formatDate(str,format) {
    const myMomentObject = moment(str, 'YYYY-MM-DD HH:mm:ss')
    const result = myMomentObject.format(format);

    return result;
  }

  return (
    <div className="receipt-info">
      <div className="receipt-info__text">
        <p>{formatDate(props.data.date, "DD-MM-YYYY")}</p>
        <p>{formatDate(props.data.time, "HH:mm:ss")}</p>
      </div>
      <div className="receipt-info__text">
        <p>Receipt Number</p>
        <p>{props.data.receiptNumber}</p>
      </div>
      <div className="receipt-info__text">
        <p>Customer</p>
        <p><a href="#">{props.data.customer}</a></p>
      </div>
      <div className="receipt-info__text">
        <p>Collected By</p>
        <p>{props.data.collectedBy}</p>
      </div>

      <div className="receipt-info__text">
        <p>Doctor</p>
        <p>{props.data.dokter?props.data.dokter:''}</p>
      </div>

      <div className="receipt-info__text">
        <p>Skin Therapist</p>
        <p>{props.data.terapis?props.data.terapis:''}</p>
      </div>
    </div>
  );
}

export default ReceiptInfo;
